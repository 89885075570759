import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Routable } from '@tecnobit-srl/types';
import { usePageRoutable } from '@tecnobit-srl/routable-types-page/lazy';

export const useRoutableTypes = (): Components<Routable> => {
  const pageRoutable = usePageRoutable();
  return useMemo(
    () => ({
      ...pageRoutable,
    }),
    [pageRoutable]
  );
};
