import { hydrateRoot } from 'react-dom/client';
import { startTransition } from 'react';
import { DEFAULT_RESOURCES, initializeI18n } from '@tecnobit-srl/i18n';
import { App } from './components/app';

declare global {
  interface Window {
    fromSsr: true | undefined;
  }
}

hydrate().catch((e: unknown) => {
  // eslint-disable-next-line no-console -- No alternatives here
  console.error(e);
});

async function hydrate(): Promise<void> {
  const root = document.getElementById('app');
  if (!root) {
    throw new Error('No root element available');
  }

  const i18nInstance = window.fromSsr
    ? await initializeI18n(
        DEFAULT_RESOURCES,
        window.inertiaPage.locale,
        window.inertiaPage.fallbackLocale
      )
    : undefined;

  startTransition(() => {
    hydrateRoot(root, <App i18nInstance={i18nInstance} />);
  });
}
