import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Content } from '@tecnobit-srl/types';
import { useContainerContent } from '@tecnobit-srl/content-types-container/lazy';
import { useColumnContent } from '@tecnobit-srl/content-types-column/lazy';
import { useMarkdownContent } from '@tecnobit-srl/content-types-markdown/lazy';
import { useTitleContent } from '@tecnobit-srl/content-types-title/lazy';
import { useImageContent } from '@tecnobit-srl/content-types-image/lazy';
import { useButtonContent } from '@tecnobit-srl/content-types-button/lazy';
import { useGridContent } from '@tecnobit-srl/content-types-grid/lazy';
import { useYouTubeVideoContent } from '@tecnobit-srl/content-types-you-tube-video/lazy';
import { useIFrameContent } from '@tecnobit-srl/content-types-i-frame/lazy';
import { useBannerContent } from '@tecnobit-srl/content-types-banner/lazy';

export const useContentTypes = (): Components<Content> => {
  const container = useContainerContent();
  const column = useColumnContent();
  const markdown = useMarkdownContent();
  const title = useTitleContent();
  const image = useImageContent();
  const button = useButtonContent();
  const grid = useGridContent();
  const youTubeVideo = useYouTubeVideoContent();
  const iFrame = useIFrameContent();
  const banner = useBannerContent();
  return useMemo(
    () => ({
      ...container,
      ...column,
      ...markdown,
      ...title,
      ...image,
      ...button,
      ...grid,
      ...youTubeVideo,
      ...iFrame,
      ...banner,
    }),
    [
      column,
      container,
      image,
      markdown,
      title,
      button,
      grid,
      youTubeVideo,
      iFrame,
      banner,
    ]
  );
};
