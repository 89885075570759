import {
  type ReactNode,
  startTransition,
  StrictMode,
  Suspense,
  useEffect,
  useRef,
} from 'react';
import {
  Style,
  StylesProvider,
  useRegisteredStyles,
} from '@tecnobit-srl/styles-manager';
import { Head, HeadProvider } from '@tecnobit-srl/head-manager';
import { LoggerProvider } from '@tecnobit-srl/dev-logger';
import { I18nProvider } from '@tecnobit-srl/i18n';
import { RouterProvider, usePage, useReload } from '@tecnobit-srl/router';
import { type TFunction } from 'i18next';
import { type PageProps } from '@tecnobit-srl/types';
import { DebugBar } from '@tecnobit-srl/dev-debug-bar';
import { RouteTypesProvider, Route } from '@tecnobit-srl/route-types-store';
import { RoutableTypesProvider } from '@tecnobit-srl/routable-types-store';
import { ContentTypesProvider } from '@tecnobit-srl/content-types-store';
import { useThemeStyle } from '@tecnobit-srl/hook-use-theme-style';
import { LightGallery } from '@tecnobit-srl/ui-lightgallery';
// import { Spinner } from '@tecnobit-srl/ui-spinner';
import { useRouteTypes } from '../hooks/use-route-types';
import { useRoutableTypes } from '../hooks/use-routable-types';
import { useContentTypes } from '../hooks/use-content-types';
import '../panda.css';
import '../entry.css';
import { css } from '../../styled-system/css';

declare global {
  interface Window {
    totalLazyScripts?: number;
  }
}
function AppContent(): ReactNode {
  const page = usePage();

  const style = useThemeStyle();

  const ref = useRef<HTMLLinkElement>(null);
  const reload = useReload();

  const registeredStyles = useRegisteredStyles();
  useEffect(() => {
    if (
      window.totalLazyScripts !== undefined &&
      registeredStyles.length === window.totalLazyScripts
    ) {
      void reload();
    }
  }, [registeredStyles, reload]);

  return (
    <div
      className={css({
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'start',
      })}
    >
      <Head headKey="theme">
        <style>{style}</style>
      </Head>
      {page.settings.google_font ? (
        <Head headKey="google-font">
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            ref={ref}
            rel="preload"
            href={page.settings.google_font}
            as="style"
            onLoad={() => {
              const link = ref.current;
              if (link) {
                link.rel = 'stylesheet';
              }
            }}
          />
        </Head>
      ) : null}
      <Suspense>
        <Route {...page.route} />
      </Suspense>
      <DebugBar props={page} />
    </div>
  );
}

export function App({
  initialPage,
  i18nInstance,
}: {
  i18nInstance?: TFunction;
  initialPage?: PageProps;
}): ReactNode {
  const routeTypes = useRouteTypes();
  const routableTypes = useRoutableTypes();
  const contentTypes = useContentTypes();

  return (
    <StrictMode>
      <LoggerProvider>
        <HeadProvider>
          <StylesProvider>
            <Style style="entry.client" />
            <RouterProvider
              currentPage={initialPage ?? window.inertiaPage}
              loadingElement={
                <div
                  className={css({
                    w: 'full',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  {/* <Spinner /> */}
                </div>
              }
            >
              <I18nProvider
                i18nInstance={i18nInstance}
                loadingElement={
                  <div
                    className={css({
                      w: 'full',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    {/* <Spinner /> */}
                  </div>
                }
              >
                <RouteTypesProvider components={routeTypes}>
                  <RoutableTypesProvider components={routableTypes}>
                    <ContentTypesProvider components={contentTypes}>
                      <LightGallery id="images">
                        <AppContent />
                      </LightGallery>
                    </ContentTypesProvider>
                  </RoutableTypesProvider>
                </RouteTypesProvider>
              </I18nProvider>
            </RouterProvider>
          </StylesProvider>
        </HeadProvider>
      </LoggerProvider>
    </StrictMode>
  );
}
